import styled from 'styled-components'

export const Describer = styled.h1`
  color: ${props => props.theme.primary400to300};
  font: 400 var(--fontSize-3) / 1.5 var(--font-marker);
  letter-spacing: var(--letterSpacing-0);
  margin: 0 auto 0 0;
  transition: color 300ms ease-in-out;
  @media (min-width: 480px) {
    font-size: var(--fontSize-4);
  }
  @media (max-width: 980px) {
    margin: auto;
  }
`

export default Describer
