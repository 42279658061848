import * as React from 'react'
import styled from 'styled-components'
import { GatsbyImage, type IGatsbyImageData, getImage } from 'gatsby-plugin-image'

import Describer from '../../common/text/DescriberTag'
import HeaderDescription from '../../common/text/HeaderDescription'
import HeaderImageOverlay from '../../common/HeaderImageOverlay'
import HeaderContainer from '../../common/ImageHeaderContainer'

const TextContainer = styled.div<{ $continent: boolean }>`
  align-self: center;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  margin: 0 auto;
  padding: var(--spacing-header-1);
  padding-bottom: ${({ $continent }) => ($continent ? '1.5rem' : '0')};
  text-align: center;
  z-index: 2;
  width: 100%;
  @media (min-width: 768px) {
    padding: var(--spacing-header-2);
    padding-bottom: ${({ $continent }) => ($continent ? '2rem' : '0')};
  }
  @media (min-width: 980px) {
    height: ${({ $continent }) => ($continent ? 'auto' : '77vh')};
    margin: auto 0;
    margin-top: ${({ $continent }) => ($continent ? '0' : '4rem')};
    padding: var(--spacing-header-2);
    text-align: start;
    width: ${({ $continent }) => ($continent ? '100%' : '38vw')};
  }
`

const Title = styled.h1`
  color: ${props => props.theme.black200greyscale100};
  font: 700 var(--fontSize-7) / 1 var(--font-dela);
  letter-spacing: var(--letterSpacing-1);
  margin: auto;
  transition: color 300ms ease-in-out;
  @media (min-width: 768px) {
    font-size: var(--fontSize-8);
  }
  @media (min-width: 980px) {
    font-size: var(--fontSize-9);
    margin: 0;
  }
`

const Description = styled(HeaderDescription)`
  @media (min-width: 980px) {
    text-align: start;
    margin: 1.5rem auto 1rem 2rem;
  }
`

const ImageContainer = styled.div`
  position: relative;
  right: 0;
  z-index: 0;
  @media (min-width: 980px) {
    top: 0;
  }
`

const CoverImage = styled(GatsbyImage)`
  height: 58vh;
  width: auto;
  @media (min-width: 980px) {
    // height: 77.5vh;
    height: 99vh;
    top: 4rem;
    width: 62vw;
  }
`

interface Props {
  title: string
  continent?: boolean
  description: string
  image?: IGatsbyImageData
}

export default function DestHeader({ title, description, image, continent = false }: Props): JSX.Element {
  return (
    <HeaderContainer>
      <TextContainer $continent={continent}>
        <Describer>Explore</Describer>
        <Title itemProp="headline" key={title}>
          {title}
        </Title>
        {Boolean(description) && <Description key={description}>{description}</Description>}
      </TextContainer>
      {image !== undefined && (
        <ImageContainer>
          <CoverImage alt={title} image={getImage(image) as IGatsbyImageData} loading="lazy" />
          <HeaderImageOverlay />
        </ImageContainer>
      )}
    </HeaderContainer>
  )
}
