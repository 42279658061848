import React, { lazy } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import addSuspense from '../utils/AddSuspense'
import Bio from '../components/elements/bio'
import Layout from '../components/layouts/layout'
import Seo from '../components/seo'
import DestHeader from '../components/sections/headers/DestHeader'

const EventHighlight = lazy(async () => await import('../components/sections/EventsWrapper'))
const CarouselSection = lazy(async () => await import('../components/sections/CarouselSection'))

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

interface Props {
  data: any
  location: any
  pageContext: any
}

export default function cityPage({ data, location, pageContext }: Props): JSX.Element {
  const { city, country } = pageContext
  const { nodes } = data.allMarkdownRemark
  const activeContinent = data.allDataLocationsJson.nodes[0]
  const activeCountry = activeContinent.countries.find((newCountry: any) => newCountry.name === country)
  const cityData = activeCountry?.destinations?.find((newCity: any) => newCity.name === city)

  const routes = nodes.filter(
    (node: any) => node.frontmatter.type.toLowerCase() === 'route' && node.frontmatter.countries.includes(country)
  )
  const events = nodes.filter(
    (node: any) =>
      node.frontmatter.type.toLowerCase() === 'event' && node.frontmatter.city.toLowerCase() === city?.toLowerCase()
  )

  if (nodes.length === 0) {
    return (
      <Layout location={location}>
        <Bio />
        <p>Error. No destinations found.</p>
      </Layout>
    )
  }

  return (
    <Layout location={location}>
      <Contents>
        <DestHeader title={city} description={cityData?.description} image={cityData?.image} />
        {Boolean(events) && events.length !== 0 && addSuspense(<EventHighlight posts={events} />)}
        {Boolean(routes) && routes.length !== 0 && addSuspense(<CarouselSection type={'ROUTE'} posts={routes} />)}
      </Contents>
    </Layout>
  )
}

export function Head({ location }: any): JSX.Element {
  return <Seo title="Countries" description={'Find the best cities around the world'} location={location} />
}

export const pageQuery = graphql`
  query cityQuery($country: String) {
    site {
      siteMetadata {
        title
      }
    }
    allDataLocationsJson(filter: { countries: { elemMatch: { name: { eq: $country } } } }) {
      nodes {
        id
        title
        description
        countries {
          name
          description
          destinations {
            name
            country
            continent
            description
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
    allMarkdownRemark(sort: { frontmatter: { date: DESC } }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          id
          date(formatString: "MMMM DD, YYYY")
          startdate(formatString: "MMMM DD, YYYY")
          enddate(formatString: "MMMM DD, YYYY")
          title
          aka
          description
          type
          subtype
          duration
          city
          country
          countries
          continent
          hero_image_credit_text
          hero_image_credit_link
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
