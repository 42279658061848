import styled from 'styled-components'

const HeaderImageOverlay = styled.div`
  bottom: 0;
  height: 100%;
  position: absolute;
  transition: box-shadow 300ms ease-in-out;
  width: 100%;
  box-shadow: 0 1rem 0.8rem -0.1rem ${props => props.theme.greyscale200secondary900} inset;
  @media (min-width: 980px) {
    box-shadow: 1rem 0 0.8rem -0.1rem ${props => props.theme.greyscale200secondary900} inset;
  }
`

export default HeaderImageOverlay
