import styled from 'styled-components'

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  @media (min-width: 980px) {
    flex-direction: row;
  }
`

export default HeaderContainer
